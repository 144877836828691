function matc() 
		{
			mat = document.getElementById('mains1').value;

			if (mat=="room") 
			{
				document.getElementById('forms1').style.display="block";
				document.getElementById('forms2').style.display="none";
				document.getElementById('data').style.display="none";
				document.getElementById('forms3').style.display="none";
				document.getElementById('forms4').style.display="none";
				document.getElementById('forms5').style.display="none";
			}
			if (mat=="private") 
			{
				document.getElementById('forms1').style.display="none";
				document.getElementById('forms2').style.display="block";
				document.getElementById('data').style.display="none";
				document.getElementById('forms3').style.display="none";
				document.getElementById('forms4').style.display="none";
				document.getElementById('forms5').style.display="none";
			}
			if (mat=="nos") 
			{
				document.getElementById('forms1').style.display="none";
				document.getElementById('forms2').style.display="none";
				document.getElementById('forms3').style.display="none";
				document.getElementById('data').style.display="block";
				document.getElementById('forms4').style.display="none";
				document.getElementById('forms5').style.display="none";
			}
			if (mat=="pc") 
			{
				document.getElementById('forms1').style.display="none";
				document.getElementById('forms2').style.display="none";
				document.getElementById('data').style.display="none";
				document.getElementById('forms3').style.display="block";
				document.getElementById('forms4').style.display="none";
				document.getElementById('forms5').style.display="none";
			}
			if (mat=="hotel") 
			{
				document.getElementById('forms1').style.display="none";
				document.getElementById('forms2').style.display="none";
				document.getElementById('data').style.display="none";
				document.getElementById('forms4').style.display="block";
				document.getElementById('forms3').style.display="none";
				document.getElementById('forms5').style.display="none";
			}
			if (mat=="hostel") 
			{
				document.getElementById('forms1').style.display="none";
				document.getElementById('forms2').style.display="none";
				document.getElementById('data').style.display="none";
				document.getElementById('forms4').style.display="none";
				document.getElementById('forms3').style.display="none";
				document.getElementById('forms5').style.display="block";
			}
			if (mat=="taxi") 
			{
				document.getElementById('forms6').style.display="block";
				document.getElementById('forms1').style.display="none";
				document.getElementById('forms2').style.display="none";
				document.getElementById('data').style.display="none";
				document.getElementById('forms4').style.display="none";
				document.getElementById('forms3').style.display="none";
				document.getElementById('forms5').style.display="none";
			}
		}
